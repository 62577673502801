* {
  font-family: "Heebo", sans-serif;
}



.containerGallery {
  background: url("/public/images/background-fogo-tres-exposure.webp");
  background-size: cover;
}

.main-heading {
  color: var(--sun);
}
 
.Item-inside {
  height: 220px;
  border-radius: 12px;
  -webkit-box-shadow: -1px 1px 5px 1px rgba(164,164,164,0.09); 
box-shadow: -1px 1px 5px 1px rgba(164,164,164,0.09);
  
}

.btn-danger {
  background: var(--sun);
  font-weight: bold;
  margin: 0.6rem;
}

.Item-inside:hover {
  -webkit-box-shadow: 3px 6px 15px 4px rgba(255,0,0,0.81); 
box-shadow: 3px 6px 15px 4px rgba(255,0,0,0.81);

}

.Item-inside:hover,
.Item-inside:hover img,
.Item-inside:hover .btn {
  transform: scale(1.03);
}

.img-div {
  padding: 0;
}

img {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  object-fit: cover;
  transition: all 0.3s ease-in-out;
}

.img-fluid {
  height: 220px;
  width: 100%;
  background-size: cover;
}

.main-title h1 {
  font-size: 1.3rem;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--sun);
}

.main-title p {
  color: var(--text);
  font-size: 0.9rem;
}


/* .price-book-divide h2 {
  font-size: 20px;
  font-weight: 400;
  color: #4db6ac;
} */

.price-book-divide {
  margin-bottom: 10px;
}

.row {
  margin-right: 0;
  margin-left: 0px;
}

.btn-primary {
  background: var(--color-whats);
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
}

.btn-primary:hover {
  background: var(--color-whats);
  -webkit-box-shadow: 4px 4px 12px 4px var(--color-whats); 
  box-shadow: 4px 4px 12px 4px var(--color-whats);
  outline: none;
  border: none;
}

.btn-warning:hover {
  background-color: black;
  color: whitesmoke;
  outline: none;
}

